import React from 'react';
import SkeletonCard from 'components/cards/skeleton';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import useStyles from 'apputil/view-styles';

import { Paper, Grid } from '@material-ui/core';

export const SkeletonHomeView = ({ canAdd = true }) => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <Skeleton animation="wave" height={100} className={styles.bodyHeader}/>
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            {canAdd && (
              <Grid item lg={4} md={6} sm={6} xs={12}>
                  <SkeletonCard button/>
              </Grid>
            )}
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <SkeletonCard button/>
            </Grid>
          </Grid>
        </div>
        <SkeletonFooter/>
        </>
  );
};

export const SkeletonHomeAdvertisingView = () => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <Paper className={styles.paper} elevation={4}>
          <Skeleton animation="wave" height={125} className={styles.bodyHeader}/>
          <Skeleton animation="wave" height={50} className={styles.body2Indent}/>
          <div className={styles.body2Indent}>
            <Skeleton animation="wave" height={50} />
            <Skeleton animation="wave" height={50} />
          </div>
          <Skeleton animation="wave" height={100} className={styles.bodyHeader}/>
          <Skeleton animation="wave" height={50} className={styles.body2Indent}/>
        </Paper>
        <SkeletonFooter/>
      </>
  );
};
