import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  menucontent: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  icon: {
    marginTop: -5,
    marginLeft: theme.spacing(1),
  },
  topSpaceButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  grid: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  gridNoSpace: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  paper: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
  gridMargin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  gridTopBottomMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noPadNoMarin: {
    margin: '0 !important',
    padding: '0 !important',
  },
  indentText: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
  },
  indentTextNoMarginBottom: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
  },
  topMarginTwo: {
    marginTop: theme.spacing(2),
  },
});
