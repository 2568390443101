import localforage from 'localforage';
import { PASSES, SUBSCRIPTION } from 'state/store';

export const canAddPasses = async () => {
  const subscription = await localforage.getItem(SUBSCRIPTION);
  if (subscription && 'model' in subscription) {
    const { model = {} } = subscription;
    const { maxPassType = 1 } = model;
    const passes = await localforage.getItem(PASSES);
    return Array.isArray(passes) && passes.length < maxPassType;
  }
  return false;
};
