import theme from 'theme';
import buttonStyles from 'apputil/button-styles';

export default {
  ...buttonStyles,
  card: {
    cursor: 'pointer',
    position: 'relative',
    height: '100%',
  },
  nonClickCard: {
    position: 'relative',
    height: '100%',
  },
  icon: {
    marginLeft: 10,
  },
  descriptionContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  title: {
    paddingTop: theme.spacing(1),
  },
  description: {
    paddingTop: theme.spacing(1),
  },
};
