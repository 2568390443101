import React from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import useCanAddPass from 'hooks/use-can-add-pass';

import { SkeletonHomeAdvertisingView, SkeletonHomeView } from 'views/home/skeleton';
import { useNavigationContext } from 'contexts/navigation-context';

const LazyCreateLoyaltyCard = loadable(() => import('suspended-page/loyalty-card/create'));

const CreateLoyaltyCardPage = ({ location = {} }) => {
  const { canAdd, authenticated, canAddLoading } = useCanAddPass();

  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const { homePageOnly = () => {} } = useNavigationContext();

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (!canAddLoading && !canAdd) {
    homePageOnly();
    return (<SkeletonHomeView />);
  }

  return (<LazyCreateLoyaltyCard passId={passId}/>);
};

CreateLoyaltyCardPage.propTypes = { location: object };

export default CreateLoyaltyCardPage;
