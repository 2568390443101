import React from 'react';
import { bool } from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Card } from '@material-ui/core';
import useStyles from './styles';

const SkeletonCard = ({ button, qr = false }) => {
  const styles = useStyles();

  return (
      <Card className={styles.card}>
       <Skeleton animation="wave" height={qr ? 350 : 250} style={{ marginLeft: 10, marginRight: 10 }} />
        <Skeleton animation="wave" height={50} style={{ marginLeft: 10, marginRight: 10 }}/>
        <Skeleton animation="wave" height={25} style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}/>
        {button && <Skeleton animation="wave" />}
      </Card>

  );
};

SkeletonCard.propTypes = { button: bool, qr: bool };

export default SkeletonCard;
